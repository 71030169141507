import PropTypes from 'prop-types';
import { Tabs, TextInput } from '@mantine/core';
import ConnectorCard from 'components/ConnectorCard/View';
import NoSearchResult from 'pages/ControlPanel/Components/EmptyState/NoSearchResult';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { TAB_VALUES } from './constants.js';
import { ENV } from 'utils/constants.js';
import styles from './styles.module.scss';

export const Tab = ({ searchTerm, apps, setSelectedConnection }) => {
  if (searchTerm && !apps.length) {
    return (
      <div className={styles.emptyStateContainer}>
        <NoSearchResult hideSearchCount />
      </div>
    );
  }

  return (
    <div className={styles.cards}>
      {apps.map((app, index) => (
        <ConnectorCard key={index} conn={app} setSelectedConnection={setSelectedConnection} />
      ))}
    </div>
  );
};

Tab.propTypes = {
  apps: PropTypes.object.isRequired,
  setSelectedConnection: PropTypes.func.isRequired,
  searchTerm: PropTypes.string
};

Tabs.defaultProps = {
  searchTerm: ''
};

const SupportedApps = (props) => {
  const isProduction = import.meta.env.VITE_NODE_ENV === ENV.PRODUCTION;
  const { setSelectedConnection, activeTab, setActiveTab, apps, searchTerm, setSearchTerm } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>Supported connections</div>
        <TextInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          leftSection={<SearchIcon />}
          className={styles.search}
          classNames={{ input: styles.searchInput }}
          placeholder='Search for apps'
        />
      </div>
      <Tabs
        value={activeTab}
        onChange={setActiveTab}
        defaultValue='all'
        color='primaryGreen.3'
        className='tabs-style-1'
        classNames={{ panel: styles.tabsPanel, list: styles.tabList }}
      >
        <Tabs.List>
          {TAB_VALUES.map((tab, index) => {
            if (isProduction) {
              if (tab.value === 'all') {
                return (
                  <Tabs.Tab key={tab.index} value={tab.value}>
                    {tab.label}
                  </Tabs.Tab>
                );
              }
            } else {
              return (
                <Tabs.Tab key={index} value={tab.value}>
                  {tab.label}
                </Tabs.Tab>
              );
            }
          })}
        </Tabs.List>

        {TAB_VALUES.map((tab, index) => (
          <Tabs.Panel key={index} value={tab.value}>
            <Tab
              apps={apps}
              setSelectedConnection={setSelectedConnection}
              searchTerm={searchTerm}
            />
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};

SupportedApps.propTypes = {
  setSelectedConnection: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  apps: PropTypes.array.isRequired,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func
};

SupportedApps.defaultProps = {
  searchTerm: '',
  setSearchTerm: () => {}
};

export default SupportedApps;
