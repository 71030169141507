import { useEffect, useState } from 'react';
import { Flex, Skeleton, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthWrapper from '../Components/AuthWrapper';
import Sign from '../Components/Sign';
import AuthForm from '../Components/AuthForm';
import EmailVerification from '../Components/EmailVerification';
import ChooseCompany from '../Components/ChooseCompany';
import {
  useCompanyListQuery,
  useCreateCompanyMutation,
  useCreatePasswordMutation,
  useLazyDashboardSignupQuery,
  useResendVerificationMutation
} from 'redux/services/auth';
import SlackIcon from 'assets/icons/slack.svg';
import MicrosoftIcons from 'assets/icons/microsoft.svg';
import EmailIcons from 'assets/icons/email.svg';
import GoogleG from 'assets/icons/googleG.svg';
import localStorage from 'utils/localStorage.js';
import { END_POINT } from 'utils/apiEndpoints';
import { NAVBAR_ROUTES } from 'utils/constants';
import { INITIAL_VALUES, STEPS, EMAIL_NOT_VERIFIED } from '../constants';

const Signup = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const isSetup = window.location.pathname.includes('/setup');
  const isEmailSetup = isSetup && searchParams.get('provider') === 'email';
  const redirect = searchParams.get('redirect') ?? '';
  const state = searchParams.get('state');
  const email = searchParams.get('email');
  const orgName = searchParams.get('orgName');

  let VALUES;
  if (orgName) {
    VALUES = { ...INITIAL_VALUES, company: orgName };
  }

  const [step, setStep] = useState(STEPS.ZERO);
  const [formData, setFormData] = useState(INITIAL_VALUES);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [signupTrigger, { isLoading: isSignUpLoading }] = useLazyDashboardSignupQuery();
  const [resendVerification] = useResendVerificationMutation();
  const [createPassword, { isLoading: isCreatePasswordLoading }] = useCreatePasswordMutation();
  const [createCompany, { isLoading: isCreateCompanyLoading }] = useCreateCompanyMutation();

  let inviteCode = localStorage.get('inviteCode');
  const navigate = useNavigate();

  if (inviteCode == 'undefined' || inviteCode == 'null') {
    inviteCode = undefined;
  }

  const { data: companiesData, isLoading: companyListLoading } = useCompanyListQuery(
    { code: inviteCode },
    {
      skip: !isSetup
    }
  );

  useEffect(() => {
    if (isSetup) {
      if (isEmailSetup && state === STEPS.SETUP_USER) {
        setFormData({ ...formData, email: email });
        setStep(STEPS.CREATE_PASSWORD);
      } else if (!inviteCode) {
        if (state === STEPS.CHOOSE_COMPANY) {
          setStep(STEPS.CHOOSE_COMPANY);
        } else if (state === STEPS.CREATE_COMPANY) {
          setStep(STEPS.CREATE_COMPANY);
        }
      }
    }
  }, []);

  const handleCreateCompanySubmit = async (values) => {
    setFormData(values);
    const { data, error } = await createCompany({ name: values.company });
    if (data?.ok) {
      window.open(`${import.meta.env.VITE_BASE_URL}/?token=${data.data?.token}`, '_self');
    }

    if (error) {
      toast.error(error.data?.message ?? 'Oops, something went wrong. Try again!');
      navigate(NAVBAR_ROUTES.AUTH.SIGNIN);
    }
  };

  async function handleSignUpWithEmail(values) {
    setFormData(values);
    setIsSubmitting(true);
    const { data, error } = await signupTrigger({
      provider: 'email',
      email: values.email
    });

    setTimeout(() => {
      if (error?.redirectTo) {
        window.open(error.redirectTo, '_self');
      }

      if (data?.ok || error?.message === EMAIL_NOT_VERIFIED) {
        setStep(STEPS.EMAIL_VERIFICATION);
      }

      setIsSubmitting(false);
    }, 3000);
  }

  async function handleCreatePassword(values) {
    setFormData(values);
    setIsSubmitting(true);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data, error } = await createPassword({
      name: `${values.firstName} ${values.lastName}`,
      password: values.newPassword,
      timezone: userTimezone,
      code: inviteCode
    });

    if (data?.ok) {
      window.open(data.data?.redirectTo, '_self');

      if (inviteCode) {
        // In invite flow, after user has verified email, we should not shown join/create company option.
        // They will be asked to sign in to their account.
        navigate(`${NAVBAR_ROUTES.AUTH.SIGNIN}?step=${STEPS.ONE}`);
      }
    }

    if (error) {
      toast.error(error.data?.message);
    }

    setIsSubmitting(false);
  }

  const providers = [
    {
      icon: SlackIcon,
      label: 'Sign up with Slack',
      handler: () =>
        window.open(
          `${import.meta.env.VITE_API_URL}${
            END_POINT.AUTH.DASHBOARD_SIGNUP
          }?provider=slack&redirect=${redirect}`,
          '_self'
        )
    },
    {
      icon: GoogleG,
      label: 'Sign up with Google',
      handler: () =>
        window.open(
          `${import.meta.env.VITE_API_URL}${
            END_POINT.AUTH.DASHBOARD_SIGNUP
          }?provider=google&redirect=${redirect}`,
          '_self'
        )
    },
    {
      icon: MicrosoftIcons,
      label: 'Sign up with Microsoft',
      handler: () =>
        window.open(
          `${import.meta.env.VITE_API_URL}${
            END_POINT.AUTH.DASHBOARD_SIGNUP
          }?provider=microsoft&redirect=${redirect}`,
          '_self'
        )
    },
    {
      icon: EmailIcons,
      label: 'Sign up with email',
      handler: () => setStep(STEPS.ONE)
    }
  ];

  const footer = (
    <>
      Already have an account?&nbsp;
      <Text
        display='inline'
        size='sm'
        weight={500}
        c='primaryGreen.3'
        td='underline'
        className='cursor-pointer font-poppins'
        onClick={() => navigate(NAVBAR_ROUTES.AUTH.SIGNIN)}
      >
        Sign in
      </Text>
    </>
  );

  if (isSetup && companyListLoading) {
    return (
      <AuthWrapper>
        <Flex justify='center' align='center' direction='column' gap={20} h='40vh' w='40vw' p={10}>
          <Skeleton mah={50} height='100%' maw={500} width='100%' />
          <Skeleton mah={50} height='100%' maw={500} width='100%' />
          <Skeleton mah={50} height='100%' maw={500} width='100%' />
          <div>{/* placeholder */}</div>
          <Skeleton mah={50} height='100%' maw={500} width='100%' />
        </Flex>
      </AuthWrapper>
    );
  }

  return step === STEPS.CHOOSE_COMPANY ? (
    <ChooseCompany
      companiesData={companiesData}
      handleCreateCompany={() => setStep(STEPS.CREATE_COMPANY)}
    />
  ) : (
    <AuthWrapper>
      {step === STEPS.ZERO ? (
        <Sign headerText='Sign up for free' providers={providers} footerText={footer} />
      ) : null}

      {step === STEPS.ONE ? (
        <AuthForm
          isLoading={isSignUpLoading}
          header='Sign up for free'
          initialValues={formData}
          email
          hideEmailLabel
          ctaLabel='Continue'
          isSubmitting={isSubmitting}
          handleSubmitCTA={(values) => handleSignUpWithEmail(values)}
          footer={footer}
        />
      ) : null}

      {step === STEPS.EMAIL_VERIFICATION ? (
        <EmailVerification
          email={formData.email}
          handleBack={() => setStep(STEPS.ONE)}
          handleResendEmail={() => {
            resendVerification({ email: formData.email });
          }}
        />
      ) : null}

      {step === STEPS.CREATE_PASSWORD ? (
        <AuthForm
          isLoading={isCreatePasswordLoading}
          header='Create your password'
          initialValues={formData}
          email
          disableEmail
          name
          newPassword
          confirmPassword
          isInviteFlow={!!inviteCode}
          ctaLabel='Set password'
          isSubmitting={isSubmitting}
          handleSubmitCTA={(values) => handleCreatePassword(values)}
          footer={footer}
        />
      ) : null}

      {step === STEPS.CREATE_COMPANY ? (
        <AuthForm
          isLoading={isCreateCompanyLoading}
          header={
            <Text size='24px'>
              Add company to <strong>{companiesData?.domain}</strong>
            </Text>
          }
          initialValues={orgName ? VALUES : formData}
          company
          ctaLabel='Add new company'
          handleSubmitCTA={handleCreateCompanySubmit}
          footer={footer}
        />
      ) : null}
    </AuthWrapper>
  );
};

export default Signup;
