import PropTypes from 'prop-types';
import { Flex, Text, Title } from '@mantine/core';
import Button from 'components/Button';
import styles from './styles.module.scss';

const SubmitRequestSection = ({ setShowRequestModal }) => {
  return (
    <Flex direction='column' gap={8} className={styles.submitRequestContainer}>
      <Title size={18} fw={600} lh={1.8}>
        Request for more actions here!
      </Title>
      <Text size={14} fw={400} lh={1.5} maw={1280} c='#5E5873'>
        Can&apos;t find your app? Looking for more actions? Tell us about it and we will build
        actions tailored for you!
      </Text>
      <Button className={styles.requestBtn} onClick={() => setShowRequestModal(true)}>
        Suggest now
      </Button>
    </Flex>
  );
};

SubmitRequestSection.propTypes = {
  setShowRequestModal: PropTypes.func.isRequired
};

export default SubmitRequestSection;
